<template>
    <div class="bg-white box-shadow border-r px-20 pb-20">
        <div class="d-flex-center px-15 border-bottom">
            <h4 class="mr-auto color-333">KYC Verify</h4>
            <el-tag effect="plain" hit :type="verifyState[formData.verified][0]">{{ verifyState[formData.verified][1] }}</el-tag>
        </div>
        <div v-loading="loading" class="p-20">
            <el-form
                ref="ruleForm"
                class="input-form mx-auto"
                :model="formData"
                :show-message="false"
                :status-icon="true"
                :rules="formData.verified >= 2 ? [] : rules"
            >
                <el-form-item prop="id_name">
                    <el-input v-model="formData.id_name" :readonly="formData.verified >= 2" prefix-icon="el-icon-postcard" placeholder="Enter ID Name" />
                </el-form-item>
                <el-form-item prop="id_number">
                    <el-input v-model="formData.id_number" :readonly="formData.verified >= 2" prefix-icon="el-icon-postcard" placeholder="Enter ID Number" />
                </el-form-item>
                <el-form-item v-if="formData.verified < 2" prop="id_image_a">
                    <el-upload
                        action=""
                        accept="image/*"
                        :multiple="false"
                        :show-file-list="false"
                        :before-upload="onUploadImageA"
                        class="w-100 text-center"
                    >
                        <el-button type="warning" icon="el-icon-upload" plain class="bg-t w-100">Click me upload Image</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item v-if="formData.id_image_a" class="d-flex-center">
                    <el-image class="mx-auto voucher-img" :src="formData.id_image_a" />
                </el-form-item>
                <!-- <el-form-item v-if="formData.verified < 2" prop="id_image_b">
                    <el-upload
                        action=""
                        accept="image/*"
                        :multiple="false"
                        :show-file-list="false"
                        :before-upload="onUploadImageB"
                        class="w-100 text-center"
                    >
                        <el-button type="warning" icon="el-icon-upload" plain class="bg-t w-100">Click me upload Image B</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item v-if="formData.id_image_b" class="d-flex-center">
                    <el-image class="mx-auto voucher-img" :src="formData.id_image_b" />
                </el-form-item> -->
                <el-form-item v-if="formData.verified < 2">
                    <el-button type="primary" icon="el-icon-s-promotion" class="w-100" native-type="button" @click="submitForm">Submit</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loadingData: true,
            loading: false,
            formData: {
                id_name: null,
                id_number: null,
                id_image_a: null,
                // id_image_b: null
            },
            verifyState: [
                ['info', 'Unsubmitted'],
                ['danger', 'Fail'],
                ['primary', 'Pending'],
                ['success', 'Success']
            ],
            rules: {
                id_name: [
                    { required: true, trigger: 'blur' }
                ],
                id_number: [
                    { required: true, trigger: 'blur' }
                ],
                id_image_a: [
                    { required: true, type: 'url', trigger: 'blur' }
                ]
                // id_image_b: [
                //     { required: true, type: 'url', trigger: 'blur' }
                // ]
            }
        }
    },
    created: function() {
        this.fatchData()
    },
    methods: {
        fatchData: async function () {
            const { data } = await this.$request.get('user/verify')
            this.formData = data
            this.loading = false
        },
        onUploadImageA: function (file){
            return this.onUploadImage(file, 'id_image_a')
        },
        // onUploadImageB: function (file){
        //     return this.onUploadImage(file, 'id_image_b')
        // },
        onUploadImage: function (file, key){
            if (file.size > 8388608) {
                this.$message({
                    message: 'Image File Size Limit 8MB',
                    type: 'error'
                })
                return false
            }
            this.loading = true
            const sendData = new FormData()
            sendData.append('file',file)
            this.$request.post('upload', sendData).then(({ data }) => {
                this.formData[key] = data
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
            return false
        },
        submitForm: async function () {
            try {
                await this.$refs.ruleForm.validate()
                this.loading = true
                await this.$request.post('user/verify_submit', this.formData)
                this.formData.verified = 2
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.input-form{
    width: 400px;
}
.voucher-img{
    width: 320px;
    height: 180px;
}
</style>